import React, { useState, useEffect, Component } from 'react';
import { Helmet } from 'react-helmet'
import Product from "../../templates/common/product";
import { connect } from 'react-redux'
import dispatch from '../../templates/store';
import {getOrLoginUser, logoutUser, receiveProducts} from '../../templates/actions'
import HeaderOne from "../../templates/common/headers/header-one";
import {Link} from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import "./style.css"

const axios = require('axios');

const HomePage = (props) => {
    const [products, setProducts] = useState([
        {id: 1, name: "Blogger's Delight", slug: "bloggers-delight", details: "Start Premium Blogging Today. Includes 1 Domain Name, A Dashboard For All Your Blogging Needs, & More!", sample_link: "https://blog.biscuitandchai.com", starting_at: 'Starting at $15.99 / month', price: 'Starting at $15.99 / month', costs: [{price: "$15.99", frequency: "/ month"}, {price: "$172.99", frequency: "/ year"}]},
        {id: 2, name: "Website Au Lait", slug: "website-au-lait", details: "Build Your Personal Website. Includes 1 Domain Name, A Website Builder, & More!", sample_link: "https://blog.biscuitandchai.com", starting_at: 'Starting at $9.99 / month', price: 'Starting at $9.99 / month', costs: [{price: "$9.99", frequency: "/ month"}, {price: "$99.99", frequency: "/ year"}]},
        {id: 3, name: "Menu Latte", slug: "menu-latte", details: "Perfect To Display Your Restaurant's Menu. Includes 1 Domain, A Dashboard to Add Items, & More!", sample_link: "https://blog.biscuitandchai.com", starting_at: 'Starting at $19.99 / month', price: 'Starting at $19.99 / month', costs: [{price: "$19.99", frequency: "/ month"}, {price: "219.99", frequency: "/ year"}]},
        {id: 4, name: "Royal Wedding Tea", slug: "royal-wedding-tea", details: "Send and Store RSVPs to Your Perfect Day! Includes 1 Domain, A Dashboard to Add Guests & View RSVPS & More!", sample_link: "https://blog.biscuitandchai.com", starting_at: 'Starting at $16.99 / month', price: 'Starting at $16.99 / month', costs: [{price: "$16.99", frequency: "/ month"}, {price: "178.99", frequency: "/ year"}]},
    ]);

    useEffect(() => {
        //TEMP FOR TESTING
        dispatch.dispatch(receiveProducts(products))
        //

        axios.post("/api/shop/inventory", {  })
            .then(({ data }) => {
                dispatch.dispatch(receiveProducts(data.products));
                setProducts(data.products)
            }).catch(error => {
            console.error(error);
        })
    });

    return (
        <div>
            {/*SEO Support*/}
            <Helmet>
                <title>Biscuit & Chai</title>
                <meta name="description" content="Brewing Thoughtfully Designed Products" />
            </Helmet>
            {/*SEO Support End */}

            <h1 className={"text-center"}>WE'RE CURRENTLY IN DEVELOPMENT / TESTING MODE!</h1>

            <HeaderOne storeName={"Biscuit & Chai"} />

            <div className="container">
                <div className="row">
                    {products.map((p, i) => {
                        return (
                            <div key={i} className={"col-md-4"}>
                                <Product product={p} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    user: state.userData.user,
    cart: state.cartList.cartObj,
    cartItems: state.cartList.cart
})

export default connect(
    mapStateToProps, { getOrLoginUser, logoutUser, receiveProducts }
)(HomePage)
