import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    RECEIVE_CART,
    RECEIVE_PURCHASE_ITEM,
    CLEAR_CART } from "../constants/ActionTypes";

import {
  parseMoney, multiplyByQty
} from '../helpers';

export default function cartReducer(state = {
    cart: [],
    cartObj: {}
}, action) {
    switch (action.type) {
        case RECEIVE_CART:
        return {  cart: action.cart.purchase_items, cartObj: action.cart };

        case RECEIVE_PURCHASE_ITEM:
        return {...state,
          cart: action.purchase_items
        }

        case ADD_TO_CART:
            if (state.cart.findIndex(product => product.id === action.id) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.id) {
                        let price = multiplyByQty(product.price, action.qty)
                        let tax_amount = action.product.tax_amount;

                        cartAcc.push({ ...product, tax_amount: tax_amount, qty:  action.qty, variant: product.variant, sum: parseMoney(price) }) // Increment qty

                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            let price = multiplyByQty(action.product.price, action.qty)

            return { ...state, cart: [...state.cart, { ...action.product, id: action.id, qty: action.qty, sum: parseMoney(price) }] }

        case REMOVE_FROM_CART:
            return {
              ...state,
              cart: state.cart.filter(item => item.id !== action.id )
            }

        case CLEAR_CART:
          return {
            ...state,
            cart: []
          }
        default:
    }
    return state;
}
