import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './components/templates/store';
import './index.css';
import HomePage from "./components/containers/Home";
import ProductPage from "./components/containers/Product";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter basename='/' >
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/product" element={<HomePage />} />
                <Route exact path="/product/:product_slug" element={<ProductPage />} />

            </Routes>
        </BrowserRouter>
    </Provider>
);
