const PurchaseItem = require('./PurchaseItem.js');

module.exports = class Cart {
  constructor(cart, purchase_items) {
    this.id = cart.id;

    let allPurchaseItems = [];
    if (purchase_items) {
      if (purchase_items.length > 0) {
        for (let purchase_item of purchase_items) {
          let purchaseItem = new PurchaseItem(purchase_item);

          allPurchaseItems.push(purchaseItem);
        }
      }
    }

    this.purchase_items = allPurchaseItems;
    this.customer = cart.customer;

    this.is_empty = cart.is_empty;
    this.completed = cart.completed;
  }
}
