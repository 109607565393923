const currency = require('currency.js');

module.exports = class PurchaseItem {
  constructor(purchase_item) {
    this.id = purchase_item.id;
    this.qty = purchase_item.qty;

    this.price = currency(purchase_item.price).value;

    this.cart_id = purchase_item.cart_id;
    this.product_id = purchase_item.product_id;
    this.order_id = purchase_item.purchase_id;

    this.sum = currency(this.price).multiply(this.qty)
  }
}
