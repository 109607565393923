import React, { Component } from 'react';
import { addToCart, clearCart } from '../../actions'
import {connect} from "react-redux";
import "./style.css"
import {Link} from "react-router-dom";

class ProductListItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stock: 'In Stock',
            quantity: 1,
            image: '',
        }
    }

    toSlug(name) {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
    }

    addToCart = (item) => {
        this.props.addToCart(item, this.props.cart ? this.props.cart.id : null);
    }

    render() {
        const { product } = this.props;

        return (
            <div className="product-box">
                <div role="button" tabIndex={0} aria-label={`View ${product.name} Details`} className="img-wrapper text-center" onClick={() => this.setState({ detailModalOpen: true })}>
                    <div className="front">
                        <img
                            src={`https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/biscuitandchai/${this.toSlug(product.name)}.png`}
                            className="img-fluid product-listing-box-image"
                            alt={`${product.name}`}
                            onLoad={(e) => {
                                e.target.style.opacity = 1;
                            }}
                            onError={(e) => {
                                e.target.src = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/biscuitandchai/image-placeholder.jpeg'
                            }} />
                    </div>
                </div>
                <div tabIndex={0} aria-label={`View ${product.name} Details`} className="product-detail text-center">
                    <div className='pb-2'>
                        <h4>{product.name}</h4>
                        <a href={"https://blog.biscuitandchai.com"} target={"_blank"}>View Sample</a>
                        <h6>{product.details}</h6>
                        {product.sale_price ?
                            <h5 style={{color: 'red'}}>
                                On Sale {product.sale_price}
                                <del>
									<span className="money">
										{product.price}
									</span>
                                </del>
                            </h5>
                            :
                            <h5>
                                <span className="money">
									{product.price}
								</span>
                            </h5>
                        }
                        <Link to={`/product/${product.slug}`} className='add-to-cart text-white bg-primary border-0'>
                            VIEW
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.productId;
    return {
        // eslint-disable-next-line
        item: state.data.products.find(el => el.id == productId),
        products: state.data.products,
        symbol: state.data.symbol,
        cartItems: state.cartList.cart,
        cart: state.cartList.cartObj,
        user: state.userData.user
    }
}

export default connect(mapStateToProps, { addToCart, clearCart })(ProductListItem);
