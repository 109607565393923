import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet'
import Pace from 'react-pace-progress'
import { connect } from 'react-redux'
import dispatch from '../../templates/store';
import {getOrLoginUser, logoutUser, addToCart} from '../../templates/actions'
import HeaderOne from "../../templates/common/headers/header-one";
import {Link} from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import "./style.css"
import { useParams } from "react-router-dom";

const axios = require('axios');

const ProductPage = (props) => {
    const { product_slug } = useParams();
    const [product, setProduct] = useState({});
    const [domain, setDomain] = useState("");
    const [domainInfo, setDomainInfo] = useState("");
    const [availableDomain, setAvailableDomain] = useState(false);
    const [startPace, setStartPace] = useState(false);
    const [startPurchase, setStartPurchase] = useState(false);
    const [priceOptionSelected, setPriceOptionSelected] = useState(-1);

    useEffect(() => {
        setProduct(props.products.find(el => el.slug == product_slug));
    });

    const onChange = (e) => {
        setDomain(e.target.value);
    };

    const addToCart = () => {
        props.addToCart(product, props.cart ? props.cart.id : null);
    }

    const purchaseNow = () => {
        setStartPurchase(true)
    }

    const cancelPurchase = () => {
        setStartPurchase(false)
    }

    const searchDomainAvailable = () => {
        setStartPace(true)
        setAvailableDomain(false)
        setDomainInfo("")
        axios.get(`https://biscuitandchai.com/api/check_domain_availability?domain=${domain}`).then(res => {
            let { data } = res;
            setStartPace(false);

            let response = data["ApiResponse"]["CommandResponse"];

            if (response && response.length > 0) {
                console.log(response);
                let results = response[0]["DomainCheckResult"];
                if (results && results.length > 0) {
                    console.log(results[0]["ATTR"]["Available"]);

                    if (results[0]["ATTR"]["Available"] == "true") {
                        setAvailableDomain(true)
                        setDomainInfo(`${domain} Is Available!`)
                    } else {
                        setAvailableDomain(false)
                        setDomainInfo(`${domain} Is Not Available. Please Try A Different Domain.`)
                    }
                }
            }

        }).catch(error => {
            console.log(error);
            setStartPace(false);
        })
    }

    const goToCheckout = () => {

    }

    const goToLoginThenCheckout = () => {
        //add to cart, log in or register, go to cart page to complete checkout flow.
    }

    return (
        <div>
            {/*SEO Support*/}
            <Helmet>
                <title>Biscuit & Chai</title>
                <meta name="description" content="Brewing Thoughtfully Designed Products" />
            </Helmet>
            {/*SEO Support End */}

            <HeaderOne storeName="Biscuit & Chai" />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="product-box">
                            <div role="button" tabIndex={0} aria-label={`View ${product.name} Details`} className="img-wrapper text-center">
                                <div className="front">
                                    <img
                                        src={`https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/biscuitandchai/${product.slug}.png`}
                                        className="img-fluid product-listing-box-image"
                                        alt={`${product.name}`}
                                        onLoad={(e) => {
                                            e.target.style.opacity = 1;
                                        }}
                                        onError={(e) => {
                                            e.target.src = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/biscuitandchai/image-placeholder.jpeg'
                                        }} />
                                </div>
                            </div>
                            <div tabIndex={0} aria-label={`View ${product.name} Details`} className="product-detail text-center">
                                <div className='pb-2'>
                                    <h4>{product.name}</h4>
                                    <a href={"https://blog.biscuitandchai.com"} target={"_blank"}>View Sample</a>
                                    <h6>{product.details}</h6>
                                    {product.sale_price ?
                                        <h5 style={{color: 'red'}}>
                                            On Sale {product.sale_price}
                                            <del>
                                                <span className="money">
                                                    {product.price}
                                                </span>
                                            </del>
                                        </h5>
                                        :
                                        <h5>
                                            <span className="money">
                                                {product.price}
                                            </span>
                                        </h5>
                                    }

                                    <div className="domain domain-search">
                                        <input type={"text"} placeholder={"Search For Your Domain"} onChange={onChange} value={domain}  />

                                        <button className='search-domain text-white bg-primary border-0' onClick={searchDomainAvailable}>
                                            SEARCH
                                        </button>
                                    </div>

                                    { startPace ? <Pace /> : null}

                                    <div className="domain domain-list">
                                        <h2>{ domainInfo }</h2>
                                        { availableDomain ?
                                            <>
                                                <div className="row">
                                                    {product.costs && product.costs.map((c, i)=> {
                                                        return (
                                                            <div className={`price-options col-md-${12/product.costs.length} text-center`} key={i}>
                                                                <h5 className={"price-option"}>{c.price} {c.frequency}</h5>
                                                                <input type="radio" name={"price-option"} id={`price-option-${i}`} value={c.id ?? 0} onChange={() => setPriceOptionSelected(i) } />
                                                                <label className={"price-option-label"} htmlFor={`price-option-${i}`}>&nbsp;&nbsp;Select This Option</label>
                                                                <ul className={"price-option-list"}>
                                                                    <li>Offers this</li>
                                                                    <li>Offers that</li>
                                                                    <li>And this</li>
                                                                    <li>And that</li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {props.user ?
                                                    <>
                                                        <div>
                                                            { props.user.cc_last_four && props.user.cc_exp ?
                                                                <>
                                                                    <p>Use Default Card</p>
                                                                    <div className="user-cc default-card">
                                                                        <p className="last_four">
                                                                            xx{props.user.cc_last_four}
                                                                        </p>
                                                                        <p>
                                                                            {props.user.cc_exp}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                                : null
                                                            }
                                                            <div className="user-cc">
                                                                <p>+ Add New Card</p>
                                                            </div>
                                                            { props.user.billing_address ?
                                                                <div className="user-billing default-user-billing">
                                                                    <input id={"billing_address"} type={"checkbox"} checked={true} name={"billing_address"} />
                                                                    <label htmlFor="billing_address" className="billing_address_label"> {props.user.billing_address}</label>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div className="user-billing">
                                                                <p>+ Add New Billing Address</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                <button className='add-to-cart text-white bg-primary border-0' onClick={addToCart}>
                                                    ADD TO CART
                                                </button>
                                                <button className='add-to-cart purchase-now text-white bg-primary border-0' onClick={purchaseNow}>
                                                    PURCHASE NOW
                                                </button>
                                            </>
                                            : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={startPurchase} onClose={cancelPurchase} center>
                <h3>Purchase {product.name}</h3>
                <p>{product.details}</p>

                <h4 className={"text-center"}>{domain}</h4>

                <div className="row">
                    { priceOptionSelected != -1 ?
                        <div className={`price-options col-md-12 text-center`}>
                            <h5 className={"price-option"}>{product.costs[priceOptionSelected].price} {product.costs[priceOptionSelected].frequency}</h5>
                            <ul className={"price-option-list"}>
                                <li>Offers this</li>
                                <li>Offers that</li>
                                <li>And this</li>
                                <li>And that</li>
                            </ul>
                        </div>
                        :
                        null
                    }
                </div>

                {props.user ?
                    <>
                        <div className="text-center">
                            { props.user.cc_last_four && props.user.cc_exp ?
                                <>
                                    <p>Use Default Card</p>
                                    <div className="user-cc default-card">
                                        <p className="last_four">
                                            xx{props.user.cc_last_four}
                                        </p>
                                        <p>
                                            {props.user.cc_exp}
                                        </p>
                                    </div>
                                </>
                                : null
                            }
                            <div className="user-cc">
                                <p>+ Add New Card</p>
                            </div>

                            { props.user.billing_address ?
                                <div className="user-billing default-user-billing">
                                    <input id={"billing_address"} type={"checkbox"} checked={true} name={"billing_address"} />
                                    <label htmlFor="billing_address" className="billing_address_label"> {props.user.billing_address}</label>

                                    <p>+ Add New Billing Address</p>
                                </div>
                                :
                                <div className="user-billing">
                                    <div className="row">
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="First name" name="first_name" />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="Last name" name="last_name" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="Address 1" name="address_one" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="Address 2" name="address_two" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="City" name="city" />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="State" name="state" />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" placeholder="Zip Code" name="zip_code" />
                                        </div>
                                    </div>
                                </div>
                            }

                            <input id="terms-accept" name="terms-accept" type={"checkbox"}/>
                            <label htmlFor="terms-accept">&nbsp;&nbsp;I accept the Terms of Services and Policies</label>
                            <br/>
                            <button className='add-to-cart purchase-now text-white bg-primary border-0 text-center' onClick={goToCheckout}>
                                PURCHASE NOW
                            </button>

                        </div>
                    </>
                    :
                    <div className="text-center">
                        <button className='add-to-cart purchase-now text-white bg-primary border-0' onClick={goToLoginThenCheckout}>
                            LOG IN / REGISTER
                        </button>
                    </div>
                }
            </Modal>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.userData.user,
        products: state.data.products,
        cartItems: state.cartList.cart,
        cart: state.cartList.cartObj
    }
}

export default connect(
    mapStateToProps, { getOrLoginUser, logoutUser, addToCart }
)(ProductPage)
