module.exports = class User {
  constructor(user, billing_address) {
    this.id = user.id;
    this.email = user.email;
    this.first_name = user.first_name;
    this.last_name = user.last_name
    this.name = user.first_name + ' ' + user.last_name;
    this.phone_number = user.phone_number;
    this.confirmed = user.confirmed;

    if (user.cc_last_four) {
      this.cc_last_four = user.cc_last_four;
    }
    if (user.cc_exp) {
      this.cc_exp = user.cc_exp;
    }

    if (billing_address) {
      this.billing_address = user.billing_address;
    }

    if (user.marketing_opt_in) {
      this.marketing_opt_in = user.marketing_opt_in;
    }
  }
}
