import * as types from '../constants/ActionTypes'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  getTaxAmount
} from '../helpers';
import User from '../models/User';
import Cart from '../models/Cart';

const axios = require('axios');

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})
export const clearProducts = () => ({
    type: types.CLEAR_PRODUCTS
})
export const fetchUser = () => ({
  type: types.FETCH_USER
});

export const receiveUser = user => ({
    type: types.RECEIVE_USER,
    user
})

export const updateMyPreferences = (marketing_opt_in) => ({
    type: types.UPDATE_PREFERENCES,
    marketing_opt_in
})


export const receivePurchaseItem = (purchase_items) => ({
    type: types.RECEIVE_PURCHASE_ITEM,
    purchase_items
})

export const receiveCart = (cart, purchase_items) => ({
    type: types.RECEIVE_CART,
    cart,
    purchase_items
})

export const clearCart = () => ({
    type: types.CLEAR_CART
})

const baseURL = "https://biscuitandchai.com/api"

export const getOrLoginUser = (email, password = null) => dispatch => {
  dispatch(fetchUser());
  let pswd = password ? password : '';
  axios.post(`${baseURL}/login`, {email: email, password: pswd})
    .then(({data}) => {
        if (data.error) {
            dispatch(receiveUser(null));
            dispatch(clearProducts());
            dispatch(receiveCart({}, []));
            return null;
        }

        let user = new User(data.user, data.billing_address);
        dispatch(receiveUser(user));

        if (data.cart) {
            let cart = new Cart(data.cart, data.purchase_items ? data.purchase_items : []);
            dispatch(receiveCart(cart, cart.purchase_items));
        }

        return data;
    })
}
//

export const registerUser = (email, password, purchaseItems) => dispatch => {
  dispatch(fetchUser());
    // window.grecaptcha.ready(() => {
    //     window.grecaptcha.execute(process.env[`REACT_APP_RECAPTCHA_KEY`], {action: 'signup'}).then((captcha_token) => {

            axios.post(`${baseURL}/register`, {email, password, purchaseItems})//, captcha_token})
                .then(({data}) => {

                    let user = new User(data.user, null);
                    dispatch(receiveUser(user));

                    if (data.cart) {
                        let cart = new Cart(data.cart, purchaseItems);
                        dispatch(receiveCart(cart, cart.purchase_items));
                    }

                    return data;
                })
    //     })
    // });
}

export const updatePreferences = (marketing_opt_in) => dispatch => {
  dispatch(updateMyPreferences(marketing_opt_in));
}

export const logoutUser = (id) => dispatch => {
  dispatch(receiveUser(null));
  dispatch(receiveCart({}, []));
  axios.post(`${baseURL}/logout`, {id})
    .then(({data}) => {
      return data;
    });
}

export const checkout = (cart, user) => dispatch => {
    axios.post(`${baseURL}/checkout`, {cart_id: cart.id, customer: user.id})
        .then(({data}) => {
            if (data.error) {
                toast.error("Checkout was not processed. Please Try Again Later.", {autoClose: 2000});
                return [];
            }

            dispatch(clearCart());

            let cart = new Cart(data.cart, [])
            dispatch(receiveCart(cart,cart.purchase_items));

        })
        .catch(error => {
            toast.error("Something went wrong, checkout was cancelled. Please try again later.", {autoClose: 2000});
        })
}

export const completeCheckout = (replaceCart) => dispatch => {
  dispatch(clearCart());

  dispatch(receiveCart(replaceCart,[]));
}

export const errorOnCheckout = () => dispatch => {
  toast.error("There was an error processing your order, please try again.", { autoClose: 2000 });
}

export const addToCart = (product, cart_id) => (dispatch) => {
  let asGuest = false;
  if (!cart_id) {
    asGuest = true;
  }

  let price = product.sale_price ? product.sale_price : product.price;

  if (!asGuest) {
    axios.post(`${baseURL}/add_to_cart`, {price: price, product_id: product.product_id, cart_id: cart_id})
      .then(({data}) => {
        if (data.error) {
          toast.error("Item Not Added. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }
        product.price = price;
        toast.success("Item Added to Cart", { autoClose: 2000 });
        dispatch(addToCartUnsafe(data.id, product))
        return data;
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(logoutUser());
            toast.error("Please login again, item was not added to cart.", { autoClose: 2000 });
          }
        }
      })

  } else {
    product.price = price;
    toast.success("Item Added to Cart", { autoClose: 2000 });
    dispatch(addToCartUnsafe(product.id, product)) //this is supposed to save an li item, but we don't have one so just use store product id as id for the item for now
  }

}

export const addToCartUnsafe = (id, product, qty = 1) => ({
    type: types.ADD_TO_CART,
    id,
    product,
    qty: qty
});

export const removeFromCart = (purchase_item) => (dispatch) => {
    axios.post(`${baseURL}/remove_purchase_item`, {id: purchase_item.id})
      .then(({data}) => {
        if (data.error) {
          toast.error("Something Went Wrong, Couldn't Delete Item. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }

        toast.error("Item Removed from Cart", { autoClose: 2000 });
        dispatch({
            type: types.REMOVE_FROM_CART,
            id: purchase_item.id,
        })

        return data;
      })
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
              type: types.REMOVE_FROM_CART,
              id: purchase_item.id,
          })
        }
      }
    })
};

export const emptyCart = (cart_id) => (dispatch) => {
  if (!cart_id) {
    toast.error("All Items Removed From Cart", { autoClose: 2000 });
    dispatch(clearCart());

  } else {
    axios.post(`${baseURL}/empty_cart`, { cart_id })
      .then(({data}) => {
        if (data.error) {
          toast.error("Something Went Wrong, Couldn't Delete Item. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }

        toast.error("All Items Removed From Cart", { autoClose: 2000 });
        dispatch(clearCart());

        return data;
      })
      .catch(error => {
        toast.error("Please log back in to continue.", { autoClose: 2000 });
        dispatch(receiveUser(null));
        dispatch(clearProducts());
        dispatch(receiveCart({}, []));
        return [];
      })
  }
};


export const removeSilent = (purchase_item) => (dispatch) => {
  dispatch({
      type: types.REMOVE_FROM_CART,
      id: purchase_item.id,
  })
}

export const editQty = (purchase_item,product,qty,incDecUp,cart_id) => (dispatch) => {
  let asGuest = false;
  if (!cart_id) {
    asGuest = true;
  }

  let price = product.sale_price ? product.sale_price : product.price;

  product.price = price;

  let newQty;
  if (incDecUp === 'increment') {
    newQty = parseFloat(purchase_item.qty) + qty

  } else if (incDecUp === 'decrement') {
    newQty = parseFloat(purchase_item.qty) - qty

  } else { //== udpate or create
    newQty = qty;
  }

  dispatch(addToCartUnsafe(purchase_item.id,product,newQty))

  if (!asGuest) {

    axios.post(`${baseURL}/add_to_cart`, {purchase_item_id: purchase_item.id, qty: newQty, cart_id: cart_id})
      .then(({data}) => {
        if (data.error) {
          toast.error("Item Not Updated. Please Try Again Later.", { autoClose: 2000 });
          return [];
        }

        return data;
      });
  }
}


export const silentEditQty = (item,product,qty,incDecUp,cart_id) => (dispatch) => {
  if (!qty) {
    return;
  }

  let asGuest = false;
  if (!cart_id) {
    asGuest = true;
  }

  let price = product.sale_price ? product.sale_price : product.price;

  product.price = price;

  let newQty;
  if (incDecUp === 'increment') {
    newQty = parseFloat(item.qty) + qty

  } else if (incDecUp === 'decrement') {
    newQty = parseFloat(item.qty) - qty

  } else { //== udpate or create
    newQty = qty;
  }

  var tax_amount = 0.0;
  if (product.taxable) {
    tax_amount = getTaxAmount({price: price, qty: newQty}, product);
  }

  product.tax_amount = tax_amount;

  dispatch(addToCartUnsafe(item.id,product,newQty))

  if (!asGuest) {
    axios.post(`${baseURL}/add_to_cart`, {tax_amount: tax_amount, line_id: item.id, qty: newQty, cart_id: cart_id})
      .then(({data}) => {
        if (data.error) {
          return [];
        }
        return data;
      });
  }
}


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe= (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterCategory = (category) => ({
    type: types.FILTER_CATEGORY,
    category
});

export const filterName = (name) => ({
    type: types.FILTER_NAME,
    name
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});
