import React, { Component } from 'react';
import Pace from 'react-pace-progress'
import LogoImage from "./common/logo";
import { ToastContainer } from 'react-toastify';
import './style.css'
import {Modal} from "react-responsive-modal";
import {connect} from "react-redux";
import {getOrLoginUser, registerUser, logoutUser, receiveProducts} from "../../actions";
import {Link} from "react-router-dom";
import axios from "axios";
import dispatch from "../../store";

const validator = require('email-validator');

class HeaderOne extends Component {

    constructor(props) {
      super(props);

  		this.state = {
  			isLoading: false,
            openLogin: false,
            register: false,
            accountWarning: ""
        }

        this.emailRef = React.createRef();
        this.passRef = React.createRef();
        this.passConfirmRef = React.createRef();
    }

    componentDidMount() {

    }

    openLogin = () => {
        this.setState({openLogin: true, register: false});
    }

    openRegister = () => {
        this.setState({openLogin: true, register: true});
    }

    closeLogin = () => {
        this.setState({openLogin: false});
    }

    handleAccount = () => {
        this.setState({accountWarning: ''})

        if (!validator.validate(this.emailRef.current.value)) {
            this.setState({accountWarning: '*Your email is not valid. Please check your email address and try again.'})
            return;
        }

        if (this.state.register) {
            if (this.passRef.current.value == this.passConfirmRef.current.value) {
                this.props.registerUser(this.emailRef.current.value, this.passRef.current.value, this.props.cartItems);
            } else {
                this.setState({accountWarning: "Your password does not match."})
            }
        } else {
            this.props.getOrLoginUser(this.emailRef.current.value, this.passRef.current.value);
        }

        this.setState({openLogin: false});
    }

	render() {

		return (
			<div>
                <ToastContainer />

				<header>
					{this.state.isLoading ? <Pace color="#27ae60"/> : null}
                    <LogoImage />
                    <h1>Shop {this.props.storeName}</h1>
                    { !this.props.user ?
                        <div className="account-actions">
                            <p onClick={this.openLogin}>Login</p> | <p onClick={this.openRegister}>Register</p>
                        </div>
                        :
                        <div className="account-actions">
                            <Link to="/cart">Cart ({this.props.cartItems.length})</Link>
                        </div>
                    }
				</header>

                <Modal open={this.state.openLogin} onClose={this.closeLogin} center>
                    <h2 className="account-modal-title">{this.state.register ? "Register" : "Login"}</h2>
                    <div className="account-modal">
                        <input ref={this.emailRef} type="text" placeholder="Email" />
                        <br/>
                        <input ref={this.passRef} type="password" placeholder="Password" />
                        <br/>
                        { this.state.register ? <input ref={this.passConfirmRef} type="password" placeholder="Retype Password" /> : null }
                        <p className="text-red">{this.state.accountWarning}</p>
                        <button className="btn btn-primary" onClick={this.handleAccount}>{this.state.register ? "Register" : "Login"}</button>
                    </div>
                </Modal>

            </div>
			)
	}
}

const mapStateToProps = (state) => ({
    user: state.userData.user,
    cart: state.cartList.cartObj,
    cartItems: state.cartList.cart
})

export default connect(
    mapStateToProps, { getOrLoginUser, registerUser, logoutUser, receiveProducts }
)(HeaderOne)
