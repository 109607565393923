import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage() {
    return (
        <img src={`https://biscuitandchai-general.nyc3.digitaloceanspaces.com/biscuitandchai/logo-large.png`} alt="Biscuit & Chai" className="img-fluid" style={{objectFit: 'contain', height: '125px'}} />
    );
}

export default LogoImage;
